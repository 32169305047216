import { NextUp } from 'app/blocks/homepage/next-up'
import EpisodesContentCarousel from 'app/components/content/EpisodesContentCarousel'
import React from 'react'

export const NextUpBlock: React.FC<{ block: NextUp }> = ({ block }) => {
  const { nextUpTitle, content, collection } = block || {}
  
  if (!content || content?.length === 0) {
    return null
  }

  const contentList = content.map((item, i) => {
    const series = collection.series?.find((series) =>
      series.episodes?.find((episode) => episode.content === item.id)
    )

    const episode = series?.episodes?.find((episode) => episode.content === item.id)
    const index = series?.episodes?.findIndex((episode) => episode.content === item.id)

    const itemIndex = index ? index + 1 : i + 1

    return {
      ...item,
      href: item.href,
      episodeNumber: episode?.episodeNumber || itemIndex,
      episodeName: episode?.episodeName,
      episodePart: episode?.episodePart,
    }
  })

  return (
    <EpisodesContentCarousel
      titlePrefix={nextUpTitle || 'Next up in'}
      title={block.collection.name}
      items={contentList || []}
    />
  )
}
