import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { contentFragments } from 'app/queries/content/all-content-list'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const nextUp = gql`
  fragment nextUp on NextUp {
    id
    nextUpTitle: title
    content {
      ...content
    }
    collection {
      name
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface NextUp extends GenericBlock<'NextUp'> {
  nextUpTitle: string
  content: any[]
  collection: {
    name: string
    series: {
      episodes: {
        content: string
        episodeNumber: number
        episodeName: string
        episodePart: string
      }[]
    }[]
  }
  excludedPlatforms: ExcludedPlatforms
}
